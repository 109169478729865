export default {
  codeDetail1: 'View invitation code',
  codeDetail2: 'Social platforms：',
  codeDetail3: 'Please choose：',
  codeDetail4: 'Platform nickname',
  codeDetail5: 'Please-enter',
  codeDetail6: 'Username：',
  codeDetail7: 'Please enter',
  codeDetail8: 'Search',
  codeDetail9: 'Reset',
  codeDetail10: 'Live Link Management',
  codeDetail11: 'Username',
  codeDetail12: 'Profile-Image',
  codeDetail13: 'Status',
  codeDetail14: 'Online',
  codeDetail15: 'Offline',
  codeDetail16: 'Note',
  codeDetail17: 'Save',
  codeDetail18: 'Operate',
  codeDetail19: 'View friend details',
  codeDetail20: 'Creat Live Link',
  codeDetail21: 'Live Link Management',
  codeDetail22: 'ID',
  codeDetail23: 'Platform',
  codeDetail24: 'Username',
  codeDetail25: 'Nickname',
  codeDetail26: 'First Login Time',
  codeDetail27: 'Last Login Time',
  codeDetail28: 'Number of Fans (Duplicate Removed)',
  codeDetail29: 'Heavy Powder Quantity',
  codeDetail30: 'Note',
  codeDetail31: 'Name',
  codeDetail32: 'Copy live chain',
  codeDetail33: 'Number of account links',
  codeDetail34: 'User binding',
  codeDetail35: 'Clicks',
  codeDetail36: 'Number of IPs',
  codeDetail37: 'Generate living chain',
  codeDetail38: 'No remarks yet',
  codeDetail39: 'Social platforms',
  codeDetail40: 'Add time',
  codeDetail41: 'Start time',
  codeDetail42: 'End time',
  codeDetail43: 'Nickname',
  codeDetail44: 'Serial number',
  codeDetail45: 'Social account',
  codeDetail46: 'Fan information',
  codeDetail47: 'None',
  codeDetail48: 'Failed to retrieve follow-up records',
  codeDetail49: 'Failed to retrieve fan tags',
  codeDetail50: 'Fan data',
  codeDetail51: 'Total number of login accounts',
  codeDetail52: 'Current online accounts',
  codeDetail53: 'Offline accounts',
  codeDetail54: 'Assigned port',
  codeDetail55: 'Fan status',

  codeDetail56: 'New Followers Today (Deduped)',
  codeDetail57: 'Repeated Followers Today',
  codeDetail58: 'Last Manual Reset Time',
  codeDetail59: 'Total New Followers (Deduped)',
  codeDetail60: 'Total Repeated Followers',
  codeDetail61: 'Daily Reset Time',
  codeDetail62: 'Active Link',

  codeDetail63: 'Account',
  codeDetail64: 'Nickname',
  codeDetail65: 'Platform Type',
  codeDetail66: 'Enable Active Link',
  codeDetail67: 'Disable Active Link',
  codeDetail68: 'Batch Account Reset',
  codeDetail69: 'Batch Account Removal',

  codeDetail70: 'Active Link Status',

  codeDetail71: 'Off',
  codeDetail72: 'On',
  codeDetail73: 'Please Set Account ID',
  codeDetail74: 'Unsupported Platform',

  codeDetail75: 'Online Status',
  codeDetail76: 'Nickname',
  codeDetail77: 'New Followers Today',
  codeDetail78: 'Total New Followers',
  codeDetail79: 'Repeated Followers Today',
  codeDetail80: 'Total Repeated Followers',
  codeDetail81: 'Account Link',
  codeDetail82: 'Copy',
  codeDetail83: 'Log',
  codeDetail84: 'Reset Data',
  codeDetail85: 'Delete Account',
  codeDetail86: 'View Follower Details',
  codeDetail87: 'Account Offline',

  codeDetail88: 'Notice',
  codeDetail89: 'This account is in use. Are you sure you want to go offline?',
  codeDetail90: 'Confirm',
  codeDetail91: 'Cancel',
  codeDetail92:
    'After confirming this operation, all new follower data will be cleared and converted to base followers. The total number of new followers will change. This action is irreversible. Do you want to continue?',
  codeDetail93:
    'Are you sure you want to proceed with batch removal? Note that this action is irreversible, and online accounts will be automatically skipped by the system.',
  codeDetail94:
    'Are you sure you want to remove this? This action is irreversible, and all account data will be deleted.',

  codeDetail95:
    "If unrestricted is selected, starting the port will reduce the account's total port balance.",
  codeDetail96: 'Counter Feature',
  codeDetail97:
    "If follower data retention is not needed, it's strongly recommended to keep it off to improve efficiency.",
  codeDetail98: 'Share Password',
  codeDetail99: 'New Followers Reset Time',
  codeDetail100: 'Active Link User Binding',
  codeDetail101: 'Enable IP Binding',
  codeDetail102:
    'After switching, follower counting will not be recorded. The client will be forced to log in again after confirmation. Also, the ticketing system will be disabled. Are you sure you want to proceed?',
  codeDetail103:
    'After switching, the client will be forced to log in again after confirmation. Are you sure you want to proceed?',
  codeDetail104:
    'When IP binding is enabled, numbers that meet the criteria will be switched sequentially. The same device will connect to the same number each time, preventing a single customer from adding multiple numbers.',
  codeDetail105:
    'When disabled, a new number will be assigned each time the same device connects. This may result in a single user adding multiple numbers.',

  codeDetail106: 'Can be opened directly without a password',
  codeDetail107: 'This change will take effect after the client logs in again.',
  codeDetail108: 'Please enter the ticket share password.',
  codeDetail109: 'Length must be 4 to 8 characters.',
  codeDetail110: 'Please select the new followers reset time.',

  codeDetail111: 'Please select the data to operate on',
  codeDetail112: 'Nickname Remarks',
  codeDetail113:
    'Different invitation codes (sub-accounts) share one set of fan information. Requires client version 1.13.0 or above to take effect.',
  codeDetail114: 'Customize',
  codeDetail115: 'The default opening of the split link',
  codeDetail116: 'Only supports whatsapp, other social platform input is invalid',
  codeDetail117: "Fan ID",
  codeDetail118: 'View Fans',
  codeDetail119: 'Social Account ID',
  codeDetail120: 'Main Account Nickname',
};