export const dialogProps = {
  title: String,
  show_back_btn:{
    type: Boolean,
    default: false
  },
  rowDescription: {
    type: Boolean,
    default: false
  }
}
