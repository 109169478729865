<template>
  <ElConfigProvider :locale='elementPlusLocales'>
    <router-view />
  </ElConfigProvider>
</template>

<script lang='ts' setup>
import { provide, ref, Ref, computed, watch, onMounted ,nextTick} from 'vue'
import { ElConfigProvider,ElMessage ,ElMessageBox} from 'element-plus'
import { ITheme } from '/@/type/config/theme'
import theme from '/@/config/theme'
import { useLayoutStore } from '/@/store/modules/layout'
import mitt from 'mitt'
import { elementPlusMessages,i18n} from "/@/config/i18n";
import { importAllLocales, setHtmlLang } from '/@/utils/i18n'
import { initPlatformInfo } from '/@/api/modules/user'
import router from '/@/router'
import { done, start } from 'nprogress'
import { isString } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'
const { getChannelData, loginHandler, logout } = useLayoutStore()

provide('emitter', mitt())
provide('message', ElMessage)
provide('messageBox', ElMessageBox)

if (window.opener) {
  window.opener.postMessage('子页面加载完毕', '*')
}

window.addEventListener('message', (e) => {
  if (!e.data?.key) {
    return
  }
  const useToken = localStorage.getItem("useToken");

  if (e.data.key == 'userLogin' && useToken !== e.data.data.token) {
    loginHandler(e.data.data.user, e.data.data.token);
    localStorage.setItem("useToken", e.data.data.token);
  }
}, false)


const { getSetting } = useLayoutStore()
const { locale,t } = i18n.global;
const channelInfo  = ref()

const elementPlusLocales = computed(()=> elementPlusMessages[locale.value]);
//
const f = () => {
  let themeArray = theme()
  return getSetting.theme >= themeArray.length ? themeArray[0] : themeArray[getSetting.theme]
}
onMounted(() => {
  nextTick(()=>{
    getChannel()
  })

})

const replaceMsg = (msg:object,str:string)=>{
  Object.keys(msg).forEach(k=>{
    if(typeof msg[k] === 'object'){
      Object.keys(msg[k]).forEach(k1=>{
        if (typeof msg[k][k1] !== 'object') {
          msg[k][k1] = msg[k][k1].replaceAll("%channelName%",str)
        }
      })
    }else {
      msg[k] = msg[k].replaceAll("%channelName%",str)
    }
  })
  return msg
}

// 更新平台信息
const getChannel = async() => {
  channelInfo.value = await getChannelData()
  const massage = importAllLocales()
  massage["en"] = replaceMsg(massage["en"], channelInfo.value.data.name_en)
  massage["zh-CN"] = replaceMsg(massage["zh-CN"], channelInfo.value.data.name)
  i18n.global.setLocaleMessage('en', massage["en"]);
  i18n.global.setLocaleMessage('zh-CN', massage["zh-CN"]);
  // 动态修改icon
  const favicon = document.createElement("link")
  favicon.rel = "icon";
  favicon.href = channelInfo.value.data.WebLogo;
  document.head.appendChild(favicon)
  const appTitle = import.meta.env.VITE_APP_TITLE
  const reg = new RegExp(/^(.+)(\s\|\s.+)$/)
  const currentRoute = router.currentRoute.value
  document.title = !currentRoute.meta.title
      ? appTitle
      : appTitle.match(reg)
          ? appTitle.replace(reg, `${currentRoute.meta.title}$2`)
          : isString(currentRoute.meta.langKey)?(t(currentRoute.meta.langKey)) +'|'+ t(appTitle):t(appTitle)

}

let themeStyle:Ref<ITheme> = ref(f())
watch(() => getSetting.theme, () => themeStyle.value = f())
watch(() => getSetting.color.primary, () => themeStyle.value = f())

setHtmlLang(locale.value);
</script>

<style lang='postcss'>

.layout-sidebar-sidesetting > i {
  background-color: v-bind(themeStyle.sidebarActiveBg);
  color: v-bind(themeStyle.sidebarColor);
}

.layout-sidebar {
  background-color: v-bind(themeStyle.sidebarBg);

  .layout-sidebar-logo {
    background-color: v-bind(themeStyle.logoBg || themeStyle.sidebarBg);
    color: v-bind(themeStyle.logoColor || themeStyle.sidebarColor);
  }

  .el-menu {
    background-color: v-bind(themeStyle.sidebarBg);
    border-right: 0;

    .el-menu {
      background-color: v-bind(themeStyle.sidebarChildrenBg);
    }
  }

  .el-sub-menu__title {
    color: v-bind(themeStyle.sidebarColor);
  }

  .el-menu-item {
    color: v-bind(themeStyle.sidebarColor);
  }

  .el-menu-item:focus,
  .el-menu-item:hover,
  .el-sub-menu__title:hover {
    background-color: transparent;
    color: v-bind(themeStyle.sidebarActiveColor);
  }

  .el-menu-item-group__title {
    padding: 0;
  }

  .el-menu-item.is-active {
    background-color: v-bind(themeStyle.sidebarActiveBg);
    color: v-bind(themeStyle.sidebarActiveColor);
    border-right: 3px solid v-bind(themeStyle.sidebarActiveBorderRightBg);
  }

  .el-sub-menu.is-active > .el-sub-menu__title,
  .el-sub-menu.is-active > .el-sub-menu__title > i {
    color: v-bind(themeStyle.sidebarActiveColor);
  }
}


.layout-main-navbar {
  height: 64px !important;
  background-color: v-bind(getSetting.mode === "vertical" || getSetting.isPhone ? themeStyle.navbarBg : themeStyle.sidebarBg);
  color: v-bind(getSetting.mode === "vertical" || getSetting.isPhone ? themeStyle.navbarColor : themeStyle.sidebarColor);
  width: -webkit-fill-available;
  background-color: #FFFFFF;
  .el-breadcrumb .el-breadcrumb__inner,
  .el-breadcrumb .el-breadcrumb__separator,
  .el-breadcrumb .el-breadcrumb__inner:hover,
  .el-breadcrumb .el-breadcrumb__separator:hover,
  .el-dropdown {
    color: v-bind(themeStyle.navbarColor);
  }

  .layout-sidebar-menubar {
    .el-menu {
      background-color: v-bind(themeStyle.sidebarBg);
      border-right: 0;
    }

    .el-menu--horizontal {
      & > .el-menu-item {
        color: v-bind(themeStyle.sidebarColor);
      }

      & > .el-sub-menu .el-sub-menu__title {
        color: v-bind(themeStyle.sidebarColor);
      }

      .el-menu-item:not(.is-disabled):focus,
      .el-menu-item:not(.is-disabled):hover {
        background-color: v-bind(themeStyle.sidebarActiveBg);
        color: v-bind(themeStyle.sidebarActiveColor);
      }

      & > .el-sub-menu .el-sub-menu__title:hover {
        background-color: v-bind(themeStyle.sidebarActiveBg);
        color: v-bind(themeStyle.sidebarActiveColor);
      }

      & > .el-menu-item-group__title {
        padding: 0;
      }

      & > .el-menu-item.is-active {
        background-color: v-bind(themeStyle.sidebarActiveBg);
        color: v-bind(themeStyle.sidebarActiveColor) !important;
        border-right: 3px solid v-bind(themeStyle.sidebarActiveBorderRightBg);
      }

      & > .el-sub-menu.is-active > .el-sub-menu__title,
      & > .el-sub-menu.is-active > .el-sub-menu__title > i {
        color: v-bind(themeStyle.sidebarActiveColor);
      }
    }
  }
}

.layout-main-tags {
  background-color: v-bind(themeStyle.tagsbg);
  color: v-bind(themeStyle.tagsColor);

  .layout-tags-active {
    background-color: v-bind(themeStyle.tagsActiveBg);
    color: v-bind(themeStyle.tagsActiveColor);
  }
}

.layout-main-content {
  background-color: v-bind(themeStyle.mainBg);
  padding-top:3.5rem;
}

.el-popper{
  .el-menu-item-group__title {
    padding: 0;
  }
}
</style>
