<template>
    <div class="conTop-box">
        <span class="title">{{title}}</span>
        <slot v-if="props.rowDescription"/>
        <div v-else class="float-right leading-3">
            <slot/>
            <el-button type="primary" class="btn-style" v-if="show_back_btn" @click="back">{{t('components1')}}</el-button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { dialogProps } from './props'
import { useCommon } from '/@/hooks'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps(dialogProps)

const back = () =>{
    history.go(-1)
}

</script>
<style scoped lang="postcss">
    .conTop-box{
        font-weight: 500;
        color: #262E44;
        font-size: 28px;
    }
    .btn-style{
        min-width: 90px;
    }
</style>
