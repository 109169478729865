export default {
    protocol1: '欢迎您使用我们（即%channelName%APP服务提供者，简称“我们”）的产品和服务，本服务协议适用于%channelName%APP服务提供者以网站、软件客户端以及随技术发展出现的新形态向您提供的各项产品和服务。请您务必审慎阅读、充分理解本协议各条款，特别是免除或者限制有关责任的条款，并选择接受或不接受。限制、免责条款可能以黑体加粗形式提示您注意。除非您阅读并接受本协议所有条款，否则请您不要下裁、安装或使用本软件及相关服务。您的点击同意、下裁、安装、使用、登录、等行为即视为您已阅读并同意本协议的约束。',
    protocol2: '您在法律上同意这些条款是具有约束力的规则（“条款”）。您还同意我们的隐私政策，Cookie政策，付款验证政策，并同意您有责任遵守任何应适用的当地法律，我们的隐私政策、支付政策以及其他政策构成本协议的一部分。我们可能会不时更改这些条款。如果这样做，我们会通过在网站上公布或向您发送电子邮件来通知您任何重大更改。这些条款的新版本不会追溯适用，我们会告诉您新版本条款确切的生效日期，并于发布时列于本协议首部。如果更改后仍继续使用%channelName%APP软件及服务，则表示您接受新条款。我们保留本协议未明确授予的任何和所有权利，包括但不限于系统的任何和所有权利。',
    protocol3: '违反本协议任何条款将导致您的帐户被终止。您同意自行承担使用%channelName%APP软件及服务的风险。',
    protocol4: '用户协议欢迎您使用我们（即%channelName%APP服务提供者，简称“我们”）的产品和服务，本服务协议适用于%channelName%APP服务提供者以网站、软件客户端以及随技术发展出现的新形态向您提供的各项产品和服务。请您务必审慎阅读、充分理解本协议各条款，特别是免除或者限制有关责任的条款，并选择接受或不接受。限制、免责条款可能以黑体加粗形式提示您注意。除非您阅读并接受本协议所有条款，否则请您不要下裁、安装或使用本软件及相关服务。您的点击同意、下裁、安装、使用、登录、等行为即视为您已阅读并同意本协议的约束。您在法律上同意这些条款是具有约束力的规则（“条款”）。您还同意我们的隐私政策，Cookie政策，付款验证政策，并同意您有责任遵守任何应适用的当地法律，我们的隐私政策、支付政策以及其他政策构成本协议的一部分。我们可能会不时更改这些条款。如果这样做，我们会通过在网站上公布或向您发送电子邮件来通知您任何重大更改。这些条款的新版本不会追溯适用，我们会告诉您新版本条款确切的生效日期，并于发布时列于本协议首部。如果更改后仍继续使用%channelName%APP软件及服务，则表示您接受新条款。我们保留本协议未明确授予的任何和所有权利，包括但不限于系统的任何和所有权利。违反本协议任何条款将导致您的帐户被终止。您同意自行承担使用%channelName%APP软件及服务的风险。如您在签署和接受本协议之前或之后与我们签署了与本服务有关的协议（以下简称“线下协议”）且该线下协议仍然有效的，如线下协议与本协议约定有冲突之处，以线下协议的约定为准；如线下协议未涉及的内容，以本协议的约定为准。：',
    protocol5: '1.账户条款：',
    protocol6: '您必须年满18岁才能注册%channelName%APP账户，或您的年龄必须足以在您居住的地方签',
    protocol7: '订有约束力的合同。如果您未满18岁，则未经父母或监护人的同意（代表您同意您将遵守服务条款），您不能使用%channelName%APP产品。',
    protocol8: '您需要注册或与官方客服联系申请方可使用%channelName%APP，选择帐户名称并设置密码。您的信息将根据可适用的数据保护法以及我们的隐私政策进行收集和披露。所有用户在注册我们的网站或服务时都必须提供真实和准确的信息，请勿使用无效或他人的电子邮件。',
    protocol9: '我们保留验证所有用户信息并拒绝任何用户的权利。您应对帐户中的所有活动负责，并对密码保密。您同意将任何未经授权使用您的帐户或任何其他违反安全性的行为立即通知我们。对于他人在您知情或不知情的情况下使用您的密码或帐户给您造成的任何损失，%channelName%APP不承担任何责任。如果发现有人未经您的许可使用了您的帐户，请联系telegram客服',
    protocol10: '您不得将%channelName%APP软件及服务用于任何非法或未经授权的目的。您在使用软件时，不得违反您所在司法管辖区的任何法律（包括但不限于版权法）及其他任何应适用的法律规定。',
    protocol11: '您同意%channelName%APP可以在提供服务的过程中自行或由第三方广告商通过短信、电子邮件或电子信息等多种方式向您发送广告、推广或宣传信息（包括商业与非商业信息）。您同意，对%channelName%APP服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。',
    protocol12: '2.禁止行为',
    protocol13: '您应当遵守任何应适用的法律法规的规定，不得利用%channelName%APP的服务及账户实施包括但不限于以下行为，也不得为以下行为提供便利：',
    protocol14: '（1）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一、损害国家荣誉和利益的；',
    protocol15: '（2）宣扬邪教和封建迷信的；',
    protocol16: '（3）散布谣言，扰乱社会秩序，破坏社会稳定的；',
    protocol17: '（4）散布淫秽、色情、赌博、暴力、凶杀、恐怖、骚扰、低俗或者教唆犯罪的；',
    protocol18: '（5）侮辱或者诽谤他人，侵害他人合法权益的；',
    protocol19: '（6）侵害他人知识产权、商业秘密等合法权利的；',
    protocol20: '（7）利用本软件虚构事实、隐瞒真相以误导、欺骗他人的；',
    protocol21: '（8）实施博彩、赌博游戏等非法互联网活动的；',
    protocol22: '（9）删除或者改变本软件上的所有权利管理电子信息；',
    protocol23: '（10）故意避开或者破坏著作权人为保护本软件著作权而采取的技术措施；',
    protocol24: '（11）违反国家规定，对计算机信息系统功能进行删除、修改、增加、干扰，造成计算机信息系统不能正常运行；',
    protocol25: '（12）未经允许，进入计算机信息网络或者使用计算机信息网络资源；',
    protocol26: '（13）未经允许，对计算机信息网络功能进行删除、修改或者增加的；',
    protocol27: '（14）未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；',
    protocol28: '（15）破坏本软件系统或网站的正常运行，故意传播计算机病毒等破坏性程序；',
    protocol29: '（16）以任何方式进行%channelName%APP产品或乙方授权的管理用户下的邀请码倒卖活动；',
    protocol30: '（17）其他任何危害计算机信息网络安全的；',
    protocol31: '（18）其他违反公序良俗或法律法规禁止的行为。',
    protocol32: '您承诺，您不会使用任何类型的软件或设备（无论是手动还是自动的）来“爬行”或“蜘蛛化”%channelName%APP的任何部分，也不会对%channelName%APP的任何内容进行拆解或反向工程。',
    protocol33: '您理解并同意，若您实施了本协议的任何禁止行为，或将%channelName%APP软件及服务用于任何非法或未经授权的目的，或有其他违反本协议的行为，%channelName%APP有权依合理判断对',
    protocol34: '违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。',
    protocol35: '您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；%channelName%APP因此遭受损失的，您也应当一并赔偿。若%channelName%APP因生效判决、裁决等先行承担了相关责任的，%channelName%APP有权向您全额追偿。',
    protocol36: '3.费用条款',
    protocol37: '请访问我们的Telegram客服，了解我们的所有服务的价格和最新优惠。%channelName%APP的某些功能是免费的，某些功能只能付费使用。如果您同意我们的付款政策并订购相关功能，则您承诺支付相关功能的费用。所有服务的价格（包括但不限于软件的每月订购计划费用）可能会发生变化。',
    protocol37_1: '独角兽产品一经支付，账户开启，则视为服务已完成，后续概不退款。',
    protocol38: '我们保留随时或不定期更改或终止本服务（或其任何部分）的权利。%channelName%APP无须就对本服务的任何修改、价格变动、暂停或终止对您或任何第三方承担任何责任。',
    protocol39: '4.协议终止',
    protocol40: '本协议将自您接受本使用条款之日起生效，并且有效期为长期，直到任何一方将其终止。%channelName%APP有权随时出于任何原因暂停或终止您的帐户或服务，可能导致您的帐户被停用或删除，或您对帐户的访问被限制，以及帐户中所有内容被删除或清除，%channelName%APP将不承担任何赔偿责任。%channelName%APP保留随时出于任何原因拒绝向任何人提供服务的权利。',
    protocol41: '您可以随时通过官网客服或通过Telegram与我们联系，请注意，终止帐户后，可能会立即禁止访问我们网站或服务的某些部分。如有以下情况，我们有权与您终止本协议：',
    protocol42: '您在使用我们的网站或服务时违反了任何适用法律；',
    protocol43: '您违反本协议或网站或服务的条款；',
    protocol44: '我们认为您的任何行为在法律上损害我们的商业利益。',
    protocol45: '5.知识产权',
    protocol46: '%channelName%APP网站或产品中的所有的内容、设计、文本、图表、图像、标识、按钮、图标、界面、音频及视频以及以上内容的所有排列组合均为%channelName%APP或其相应内容提供商的专属财产，受可适用的著作权法保护。网站所使用的所有软件为%channelName%APP或其相应的软件供应商的财产，受可适用的著作权法保护和其他相关法律和条约保护。网站用户不得发送/上传任何侵犯他人著作权或其他知识产权的内容至本网站。',
    protocol47: '%channelName%APP网站或产品使用的所有商标、服务标识和商业名称（统称“标识”）均为%channelName%APP独有。该等标识不得用于任何非%channelName%APP的产品或服务，或任何导致消费者混淆、或以任何方式诋毁或败坏%channelName%APP名声的其他产品或服务上。',
    protocol48: '6.保密和反馈',
    protocol49: '我们重视您的隐私，我们的隐私权政策已纳入本协议，请查看我们的隐私政策，以便您了解我们的隐私惯例。我们收集的所有信息均受我们的隐私政策的约束，通过使用网站或服务，您同意我们根据隐私政策对您的信息采取的所有措施。',
    protocol50: '您同意对机密信息保密，不得将其用于履行本协议以外的目的，也不得向第三方披露您知道的%channelName%APP机密信息。您向我们提供的有关任何%channelName%APP软件或服务的任何建议、信息、想法或反馈，包括但不限于您在使用软件或相关服务时发现的任何错误的报告文档（统称“反馈”），均将归公司所有。您同意在全球范围内将“反馈”中的所有权利，包括但不限于所有权和利益以及相关的知识产权无偿转让给%channelName%APP，并同意协助我们完善和行使这些权利。',
    protocol51: '7.免责声明',
    protocol52: '我们建议您经常备份数据。您应始终有责任减轻损失。此软件不是容错软件，也不是设计用于或旨在用于任何需要保证安全性能或操作的危险环境中。%channelName%APP不保证包含服务的网站或产品将是无错误的或无中断的，也不承诺该网站或服务或服务器不受任何有害组件的影响。%channelName%APP不对提供的服务是准确，完整或有用的作任何陈述或保证。%channelName%APP并不保证您在任何特定的司法管辖区均合法使用该网站或服务，并且我们特别声明不作任何此类保证。',
    protocol53: '我们不会介入用户之间或用户与任何第三方之间有关使用服务的纠纷。当您使用服务时，您承诺%channelName%APP将免遭于与此类纠纷和服务有关的任何索赔和要求（不论已知或未知，可疑或意外，公开或未公开）。您将自行承担因使用%channelName%APP网站或服务而招致的任何结果，您对因您行为而对任何一方造成的任何损害或损失负全责。',
    protocol54: '任何使用、测试或评估%channelName%APP的用户都应对%channelName%APP的质量和性能自行承担全部风险。我们对任何形式的损害概不负责，包括但不限于因使用、执行或交付%channelName%APP而造成的直接或间接损害，即使%channelName%APP已被告知存在或可能造成这种损害。',
    protocol55: '某些国家（地区）不允许限制或排除偶发或继发性损害的责任，因此上述限制或排除可能对您不适用。在任何情况下，我们的责任均不会超过您为%channelName%APP支付的购买价格。无论您是否接受使用、评估或测试%channelName%APP，上述免责声明和限制均适用。',
    protocol56: '欢迎使用我们的产品和服务！本政策为了说明%channelName%APP公司（以下简称“%channelName%”）在提供相关产品服务过程中如何收集、使用、存储您的个人信息及您享有何种权利，%channelName%将通过本《%channelName%隐私政策》向您阐述相关事宜，%channelName%提醒您，在使用%channelName%提供的各项产品服务前，请务必仔细阅读并理解本《%channelName%隐私政策》，在确定充分理解并同意后方可使用%channelName%提供的产品或服务，您使用%channelName%提供的产品或服务，将视为您对本政策内容的接受和认可，如果您不同意本政策的条款，应停止使用%channelName%提供的服务。',
    protocol57: '本政策将帮助您了解以下内容：',
    protocol58: '如何收集和使用您的个人信息',
    protocol59: '如何使用Cookie和同类技术',
    protocol60: '共享、转让、公开披露您的个人信息',
    protocol61: '如何保护您的个人信息',
    protocol62: '如何访问或修改您的个人数据',
    protocol63: '您的个人信息如何在全球范围转移',
    protocol64: '本政策如何更新',
    protocol65: '%channelName%深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。%channelName%致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请在使用%channelName%的服务前，仔细阅读并了解本《隐私政策条款》。',
    protocol66: '1.如何收集和使用您的个人信息',
    protocol67: '1.1个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。',
    protocol68: '1.2%channelName%收集您的信息，用于以下用途：',
    protocol69: '(1)为了向您提供、维护、改进产品或服务。',
    protocol70: '(2)向您发送重要通知。',
    protocol71: '(3)激活或验证产品或服务。',
    protocol72: '(4)开展在法律法规允许的前提先向您推广、介绍产品。',
    protocol73: '(5)经您许可的其他用途。',
    protocol74: '1.3%channelName%仅会出于本政策所述的以下目的，收集和使用您的个人信息：',
    protocol75: '在使用%channelName%提供的服务过程中，%channelName%将记录并保存您登陆和使用本服务的信息，包括但不限于IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息、日志信息、相关设备信息及您需求的网页记录等数据。',
    protocol76: '在接受域名、备案等服务前，%channelName%将通过网站页面向您展示您需要提交的信息，以届时网站页面展示为准，如果您不使用某些服务，则无需提供相关信息。',
    protocol77: '2.如何使用Cookie和同类技术',
    protocol78: '为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好或购物篮内的商品等数据。',
    protocol79: '我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。',
    protocol80: '除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。',
    protocol81: '3.如何共享、转让、公开披露您的个人信息',
    protocol82: '3.1共享',
    protocol83: '我们不会与%channelName%以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：',
    protocol84: '(1)在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；',
    protocol85: '(2)我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。',
    protocol86: '(3)与我们的关联公司共享：您的个人信息可能会与%channelName%的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。',
    protocol87: '我们的关联公司是指任何一方现在或将来控制、受其控制或与其共同被控制的任何公司或合法机构。“控制”是指有权决定一个公司的财务和经营政策，直接或间接享有该公司50%以上的管理或决策权利，并能据以从该公司的经营活动中获取利益。',
    protocol88: '(4)与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。',
    protocol89: '3.2转让',
    protocol90: '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：',
    protocol91: '(1)在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；',
    protocol92: '(2)在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。',
    protocol93: '3.3公开披露',
    protocol94: '我们仅会在以下情况下，公开披露您的个人信息：',
    protocol95: '(1)获得您明确同意后；',
    protocol96: '(2)基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。',
    protocol97: '3.4共享、转让、公开披露用户信息时事先征得授权同意的例外',
    protocol98: '以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：',
    protocol99: '(1)与国家安全、国防安全有关的；',
    protocol100: '(2)与公共安全、公共卫生、重大公共利益有关的；',
    protocol101: '(3)与犯罪侦查、起诉、审判和判决执行等有关的；',
    protocol102: '(4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；',
    protocol103: '(5)您自行向社会公众公开的个人信息；',
    protocol104: '(6)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。',
    protocol105: '4.如何保护您的个人信息',
    protocol106: '4.1%channelName%非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。',
    protocol107: '4.2我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。',
    protocol108: '4.3请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在%channelName%直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个人信息泄露。',
    protocol109: '4.4互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。',
    protocol110: '5.如何访问或修改您的个人数据',
    protocol111: '您应确保提交的所有个人数据都准确无误，我们会尽力维护个人数据的准确和完整。为遵守适用法律的明确要求，您可能',
    protocol112: '(1)有权访问我们持有的关于您的特定的个人数据；',
    protocol113: '(2)要求我们更新或更正您的不准确的个人数据；',
    protocol114: '(3)依照法律规定要求我们删除您的个人数据。如果您想行使相关的权利，请联系我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权或权益，我们则会拒绝处理请求。',
    protocol115: '在以下情形中，您可以向我们提出删除个人信息的请求：',
    protocol116: '(1)如果我们处理个人信息的行为违反法律法规；',
    protocol117: '(2)如果我们收集、使用您的个人信息，却未征得您的明确同意；',
    protocol118: '(3)如果我们处理个人信息的行为严重违反了与您的约定。为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。',
    protocol119: '6.您的个人数据的保存和跨境转移',
    protocol120: '如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，并向您告知信息出境的目的、接收方、安全保障措施、安全风险等情况。',
    protocol121: '7.本政策如何更新。',
    protocol122: '我们的隐私政策可能变更。',
    protocol123: '未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会通过公司官网发布对本政策所做的任何变更。',
    protocol124: '对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。',
    protocol125: '本政策所指的重大变更包括但不限于：',
    protocol126: '(1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；',
    protocol127: '(2)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；',
    protocol128: '(3)个人信息共享、转让或公开披露的主要对象发生变化；',
    protocol129: '(4)您参与个人信息处理方面的权利及其行使方式发生重大变化；',
    protocol130: '(5)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；',
    protocol131: '(6)个人信息安全影响评估报告表明存在高风险时。'
}
