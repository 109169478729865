export default {
  statistics1: '推广数据',
  statistics2: '佣金累计',
  statistics3: '链接点击数',
  statistics4: '注册用户数',
  statistics5: '专属推广链接',
  statistics6: '推荐好友通过该链接注册购买后，佣金即刻到账。',
  statistics7: '您的专属推广链接',
  statistics8: '复制',
  statistics9:
    '1.首次付费:推荐的好友通过您的专属推广链接注册且首次付费，您可获得好友支付金额的{firstRecharge}为推广佣金；',
  statistics10:
    '2.好友续费:推荐的好友通过您的专属推广链接注册且购买，后续每次续费您都将获得交易金额的{afterRecharge}为推广佣金，佣金发放周期为1年。即好友持续续费，您可持续获得佣金收益。',
  statistics11: '3.佣金说明',
  statistics12:
    '•每次推荐好友购买完成后，推广佣金将即刻发放到账户余额，您可随时提现。',
  statistics13: '•佣金发放周期为1年。即好友持续续费，您可持续获得佣金收益。',
  statistics14: '•佣金金额无上限，持续推广，金额持续累积。',
  statistics15: '推广有礼',
  statistics16: '复制成功',
  statistics17: '特别说明',
  userBalance: '账号余额',
};
