export default {
  promoList1: '收益统计',
  promoList2: '推荐用户',
  promoList3: '状态',
  promoList4: '全部',
  promoList5: '注册',
  promoList6: '已付费',
  promoList7: '支付金额(U)',
  promoList8: '推荐用户支付次数',
  promoList9: '注册时间',
  promoList10: '注册',
  promoList11: '已付款',
  promoList12: '交易详情',
  promoList13: '您的佣金(U)',
};
