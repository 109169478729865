export default {
  menu1: 'Invitation Code',
  menu2: 'Fan Count Ticket',
  menu3: 'Accounts Live Link',
  menu4: 'Fan tag',
  menu5: 'Fan data',
  menu6: 'Purchase history',
  menu7: "Speech management",
  menu8: "Scripted Materials Library",
  menu9: "Keyword Reply",
  menu10: "Welcome Reply",
  menu11: 'Promote politely',
  menu12: 'Revenue Statistics',
  menu13: 'HOT',
};
