export default {
    importText: 'Import Text',
    exportText: "Batch export text",
    batchDelete: 'Batch Delete',
    batchMove: 'Batch Move to Group',
    addGroup: 'Add Group',
    upload: 'Upload',
    groupEdit: 'Edit',
    groupDelete: 'Delete',
    deleteConfirm: 'Are you sure you want to delete?',
    moveGroup: 'Move to Group',
    close: 'Close',
    submit: 'Submit',
    downloadTemplate: 'Download Template',
    uploadTemplateNotice: 'Please prepare the data for upload according to the template format',
    uploadTextFile: 'Upload File',
    uploadInstruction: 'Drag here or click to upload',
    formatSupport: 'Supported formats: xlsx, xls',
    maxItems: 'Maximum support 100 items',
    groupName: 'Group Name',
    inputGroupName: 'Please enter the group name',
    newGroup: 'New Group',
    editGroup: 'Edit Group',
    moveGroupDialogTitle: 'Move to Group',
    selectGroup: 'Select Group',
    uploadFile: 'Click to Upload',
    selectGroupMove: 'Select Group to Move',
    deleteItem: 'Delete',
    moveItem: 'Move to Group',
    totalCount: 'Total Count',
    selectAll: "Select All",
    "downloadEmotyTemp": "Download empty template",
    "transferFiles": "Transfer files",
    "dragToThisOr": "Drag to this or",
    "clickUpload": "Click to upload",
    "supportedFormats": "Supported formats",
    "maximumSupport": "Maximum support for 100 entries",
    "title": "Title",
    "content": "Content",
    "fileSizeLimit": "File size limit within 5 MB",
    "pleaseEnterATitle": "Please enter a title",
    "pleaseEnterContent": "Please enter content",
    "text": "Text",
    "image": "Image",
    "audio": "Audio",
    "video": "Video",
    "pleaseEnterAGroupName": "Please enter a group name",
    "deletedSuccessfully": "Deleted successfully",
    "deleteFailed": "Delete failed",
    "savedSuccessfully": "Saved successfully",
    "saveFailed": "Save failed",
    "failedToGetGroupedList": "Failed to get grouped list",
    "maximumContent_2000Words": "Maximum content 2000 words",
    "unsupportedFileTypes": "Unsupported file types",
    "fileSizeExceedsLimit": "File size exceeds limit",
    "updateSuccess": "Update successful",
    "updateFailed": "Update failed",
    "successfulUpload": "Upload successful",
    "uploadFailed": "Upload failed",
    "failedToObtainTheLibraryList": "Failed to obtain the library list",
    "pleaseSelectTheDataToDelete": "Please select the data to delete",
    "moveSuccessful": "Move successful",
    "moveFailed": "Move failed",
    "pleaseSelectTheDataToMove": "Please select the data to move",
    "pleaseSelectTheDataYouWantToExport": "Please select the data you want to export",
    "theOperationFailedPleaseCheckWhetherTheFileCanBeUsedNormally": "The operation failed, please check whether the file can be used normally",
    "operationSuccessful": "Operation successful",
    "textImportTemplate": "Text import template",
    "uploadInfo": {
        "image": {
            "format": "Supported formats: jpg, jpeg, gif, png",
            "size": "Size limit: Each file must not exceed 1MB"
        },
        "audio": {
            "format": "Supported formats: mp3, m4a",
            "size": "Size limit: File size must not exceed 3MB"
        },
        "video": {
            "format": "Supported formats: mp4, avi, rmvb",
            "size": "Size limit: Each file must not exceed 10MB"
        }
    },
    '未分组': 'ungrouped',
    "materialLibraryDoc": "Allows direct storage of images, audio, video, and business card materials for use by other modules.",
    "keywordReplyDoc": "Automatically sends a preset reply message after receiving a keyword or phrase.",
    "keywordReplyTitle": "Keyword Reply",
    "ruleInfo": "Rule Information",
    "talkAdd": "Add",
    "MatchingRules": "Matching Rules",
    "halfMatch": "Partial Match",
    "fullMatch": "Full Match",
    "replyContent": "Reply Content",
    "applyInvitationCode": "Apply Invitation Code",
    "allInvitationCode": "All Invitation Codes",
    "partInvitationCode": "Partial Invitation Codes",
    "delayInterval": "Delay Interval",
    "sendSetting": "Send Settings",
    "originalText": "Send Original Text",
    "translatedText": "Send Translated Text",
    "status": "Status",
    "selectMaterial": "Select Material",
    "keyword": "Keyword",
    "originalTextSend": "Send Original Text",
    keywordTitle: "Trigger Keyword",
    "originalTextSendTitle": "Send Original Text",
    "replyContentTitle": "Reply Content",
    "addMaterial": "Add Material",
    "randomReply": "Randomly Select a Reply",
    "replyAll": "Reply to All",
    "delayIntervalTitle": "Delay Interval",
    "replyMethod": "Reply Method",
    "pleaseSelect": "Please Select",
    "selectInvitationCode": "Select Invitation Code",
    pleaseEnterKeywordTitle: "Please enter the trigger keyword",
    "pleaseSelectReplyContent": "Please Select Reply Content",
    "keywordRule1": "1. Partial Match: The user’s message contains part of the keyword content. If multiple keywords are involved, the longest keyword is prioritized. If lengths are equal, a random trigger occurs.",
    "keywordRule2": "2. Full Match: The user’s message content exactly matches the keyword content.",
    "keywordRule3": "3. If there are multiple identical keywords under the same matching mode, a random reply will be triggered. Precise matches are prioritized.",
    "keywordRule4": "4. Sending Interval: Message replies are based on the set time interval.",
    "keywordRule5": "5. Reply Method: Supports replying with all content or randomly selecting one reply.",
    "keywordRule6": "6. After an autonomous reply, this keyword will no longer trigger automatic reply content for this instance.",
    "addTime": "Add Time",
    "failedToGetInvitationCodeList": "Failed to retrieve invitation code list",
    "message": "Message",
    "failedToGetKeywordReplyList": "Failed to retrieve keyword reply list",
    "operationFailed": "Operation Failed",

    "welcomeTitle": "Welcome Message Title",
    "pleaseEnterWelcomeTitle": "Please enter the welcome message title",
    "welcomeRule1": "1. The first message from a new client conversation will trigger a welcome message reply.",
    "welcomeRule2": "2. If multiple enabled welcome message templates are set, one will be randomly chosen for the welcome reply.",
    "welcomeRule3": "3. A template can contain up to 2 reply messages; upon triggering, both messages will be sent in the order they were added.",
    "welcomeRule4": "4. Welcome messages are prioritized over keyword replies, with the keyword reply following afterward.",
    "welcomeRule5": "5. Once a manual response is given, the automatic welcome reply will not be sent.",
    "failedToGetWelcomeReplyList": "Failed to retrieve welcome message reply list",
    "welcomeReply": "Welcome Message Reply",
    "welcomeReplyDoc": "The first message from a new client conversation will trigger a welcome message reply.",
    welcome1: 'Welcome',
    notSupport: 'This feature is not currently supported. Please upgrade and purchase a package.',
}