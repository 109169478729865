export default {
    'en-US':'英语',
    'zh-CN': '简体中文',
    "计数器管理后台":"%channelName%翻译/计数器管理后台",
    common1: '修改密码',
    common2: '退出登录',
    common3: '下载客户端',
    common4: '端口数：',
    common5: '到期日期：',
    common6: '剩余时间：',
    common7: '已到期，请及时续费',
    common8: '联系客服',
    common9: '充值套餐',
    common10: '帮助',
    common11: '全部状态',
    common12: '正常',
    common13: '禁用',
    common14: '有道翻译',
    common15: '谷歌翻译',
    common16: '百度翻译',
    common17: '自定义',
    common18: '无限制',
    common19: '天',
    common20: '提示',
    common21: '确认',
    common22: '取消',
    common23: '请求失败',
    common24: '登录过期，请重新登录',
    common25: '身份认证失败',
    common26: '暂无数据',
    common27: '正常模式',
    common28: '极速模式',
    common29: '共享',
    common30: '不共享'
}
