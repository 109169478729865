export default {
    importText: '导入文本',
    exportText: '批量导出文本',
    batchDelete: '批量删除',
    batchMove: '批量移动分组',
    addGroup: '添加分组',
    upload: '上传',
    groupEdit: '编辑',
    groupDelete: '删除',
    deleteConfirm: '是否确认删除',
    moveGroup: '移动分组',
    close: '关闭',
    submit: '提交',
    downloadTemplate: '下载模版',
    uploadTemplateNotice: '请根据模版的数据格式来准备上传的数据',
    uploadTextFile: '传文件',
    uploadInstruction: '拖动到这 或 点击上传',
    formatSupport: '支持格式：xlsx xls',
    maxItems: '最大支持 100 条',
    groupName: '分组名称',
    inputGroupName: '请输入分组名称',
    newGroup: '新建分组',
    editGroup: '编辑分组',
    moveGroupDialogTitle: '移动分组',
    selectGroup: '选择分组',
    uploadFile: '点击上传',
    selectGroupMove: '选择分组进行移动',
    deleteItem: '删除',
    moveItem: '移动分组',
    totalCount: '总数',
    selectAll: '全选',
    downloadEmotyTemp: '下载空的模版',
    transferFiles: '传文件',
    dragToThisOr: '拖动到这 或',
    clickUpload: '点击上传',
    supportedFormats: '支持格式',
    maximumSupport: '最大支持 100 条',
    title: '标题',
    content: '内容',
    fileSizeLimit: '文件大小在 5 MB 以内',
    pleaseEnterATitle: '请输入标题',
    pleaseEnterContent: '请输入内容',
    text: '文本',
    image: '图片',
    audio: '音频',
    video: '视频',
    pleaseEnterAGroupName: '请输入分组名称',
    deletedSuccessfully: '删除成功',
    deleteFailed: '删除失败',
    savedSuccessfully: '保存成功',
    saveFailed: '保存失败',
    failedToGetGroupedList: '获取分组列表失败',
    maximumContent_2000Words: '内容最大2000字',
    unsupportedFileTypes: '不支持的文件类型',
    fileSizeExceedsLimit: '文件大小超过限制',
    updateSuccess: '更新成功',
    updateFailed: '更新失败',
    successfulUpload: '上传成功',
    uploadFailed: '上传失败',
    failedToObtainTheLibraryList: '获取素材库列表失败',
    pleaseSelectTheDataToDelete: '请选择要删除的数据',
    moveSuccessful: '移动成功',
    moveFailed: '移动失败',
    pleaseSelectTheDataToMove: '请选择要移动的数据',
    pleaseSelectTheDataYouWantToExport: '请选择要导出的数据',
    theOperationFailedPleaseCheckWhetherTheFileCanBeUsedNormally: '操作失败请检查文件是否可正常使用',
    operationSuccessful: '操作成功',
    textImportTemplate: '文本导入模版',
    uploadInfo: {
        image: {
            format: "支持格式：jpg、jpeg、gif、png",
            size: "大小限制：单张大小不能超过1MB"
        },
        audio: {
            format: "支持格式：mp3、m4a",
            size: "大小限制：文件大小不超过3MB"
        },
        video: {
            format: "支持格式：mp4、avi、rmvb",
            size: "大小限制：单个大小不能超过10MB"
        }
    },
    '未分组': '未分组',
    materialLibraryDoc: '可直接存储、图片、音频、视频、名片素材，供其他模块使用。',
    keywordReplyDoc: '根据接收到关键词或短语消息后，将自动发送预设回复消息。',
    keywordReplyTitle: '关键词回复',
    ruleInfo: '规则介绍',
    talkAdd: '添加',
    MatchingRules: '匹配规则',
    halfMatch: "半匹配",
    fullMatch: "全匹配",
    replyContent: '回复内容',
    applyInvitationCode: '应用邀请码',
    allInvitationCode: '全部邀请码',
    partInvitationCode: '部分邀请码',
    delayInterval: '延时间隔',
    sendSetting: '发送设置',
    originalText: '原文发送',
    translatedText: '翻译后发送',
    status: "状态",
    selectMaterial: '选择素材',
    keyword: '关键词',
    originalTextSend: '原文发送',
    keywordTitle: '触发关键词',
    originalTextSendTitle: '原文发送',
    replyContentTitle: '回复内容',
    addMaterial: '添加素材',
    randomReply: '随机回复一条',
    replyAll: '回复全部',
    delayIntervalTitle: '延时间隔',
    replyMethod: '回复方式',
    pleaseSelect: '请选择',
    selectInvitationCode: '选择邀请码',
    pleaseEnterKeywordTitle: '请输入触发关键词',
    pleaseSelectReplyContent: '请选择回复内容',
    keywordRule1: '1.半匹配:用户发送的消息包含关键词的部分内容，当内容涉及到多个关键词时，优先触发长度最长的关键词。如长度相同，则随机触发。',
    keywordRule2: '2.全匹配:用户发送的消息内容和关键词的内容完全一致。',
    keywordRule3: '3.同个匹配模式下存在多个相同关键词，随机触发回复。优先精准匹配。',
    keywordRule4: '4.发送时间间隔:根据设置的时间间隔，进行消息回复。',
    keywordRule5: '5.回复方式:支持回复全部内容或随机选择一条回复。',
    keywordRule6: '6.自主回复后，该关键词本次将不再触发自动回复内容。',
    addTime: "添加时间",
    failedToGetInvitationCodeList: '获取邀请码列表失败',
    message: '消息',
    failedToGetKeywordReplyList: '获取关键词回复列表失败',
    operationFailed: '操作失败',
    welcomeReply: '欢迎语回复',
    welcomeReplyDoc: '客户端粉丝新会话的首条消息，即可触发欢迎语消息回复。',

    welcomeTitle: '欢迎语标题',
    pleaseEnterWelcomeTitle: '请输入欢迎语标题',
    welcomeRule1: '1.客户端粉丝新会话的首条消息，即可触发欢迎语消息回复。',
    welcomeRule2: '2.设置多个已启用的欢迎语模版，会随机选择一个模版进行欢迎语回复。',
    welcomeRule3: '3.一个模版最多设置2条回复内容，触发欢迎语消息后，2条内容按添加的顺序同时发送。',
    welcomeRule4: '4.欢迎语消息和关键词回复，优先发送欢迎语消息，随后在发送关键词问复。',
    welcomeRule5: '5.消息人工主动回复后，将不再的发欢迎语自动回复。',
    failedToGetWelcomeReplyList: '获取欢迎语回复列表失败',
    welcome1: '欢迎语',
    notSupport: '当前不支持使用该功能，请升级购买套餐'
  };