export default {
    "detailCode":{
        components1:"平台权限：",
        components2:"刷新",
        components3:"端口数限制：",
        components4:"可用端口数：",
        components5:"可用端口数",
        components6:"允许一个邀请码下同时登录的账号总数。",
        components7:"举例，需要同时登录5个号，此处填写5",
        components8:"请输入可用端口数",
        components9:"状态：",
        components10:"备注：",
        components11:"请输入内容",
        components12:"请输入可用会话数",
        components13:"请输入整数",
        components14:"可用会话数最小值为0",
        components15:"请选择平台权限",
        components16:"全选",
        components17:"提 示",
        components18:"确 定",
        components19:"取 消",
        components20:"在无需保留粉丝数据的情况下，强烈推荐采用极速模式以提升效率。",
        components21:"功能使用：",
        components22:"粉丝信息共享",
    },
    "shareCode":{
        components1:"邀请码：",
        components2:"一键复制",
        components3:"复制成功!",
    },
    "linkCode":{
        components1:"活链名称：",
        components2:"例如：XXX推广（未填写，默认为日期时间）",
        components3:"分配规则：",
        components4:"指定平台",
        components5:"在号码列表勾选指定号码后可以选择此项规则。",
        components6:"勾选",
        components7:"当前邀请码勾选的号码",
        components8:"请设置账号Id",
        components9:"选择平台：",
        components10:"请选择",
        components11:"全部平台",
        components12:"加载中...：",
        components13:"共计",
        components14:"账号可生成活链",
        components15:"当前邀请码下，选择的平台上的所有号码",
        components16:"用户绑定：",
        components17:"开启",
        components18:"关闭",
        components19:"关闭后，同一个设备每次访问都将重新分配一个新的号码，有可能一个用户添加多个号码",
        components20:"开启后，在符合条件的号码中，以顺序循环的方法进行切换。同一个设备每次访问连接同一个号码，防止同一个客户添加多个号码",
        components21:"绑定方式：",
        components22:"IP绑定",
        components23:"请选择平台",
        components24:"没有账号可生成活链",
        components25:"请勾选账户",
        components26:"创建成功",
        components27:"数据有误"
    },
    "listCode":{
        components1:"刷新列表",
        components2:"创建活链",
        components3:"名称",
        components4:"复制活链",
        components5:"用户绑定",
        components6:"操作",
        components7:"清除",
        components8:"删除",
        components9:"活链复制成功!",
        components10:"数据有误",
        components11:"清除成功",
        components12:"删除成功",
        components13:"确定要清除吗？",
        components14:"确定要删除吗？"
    },
    "workOrder":{
        components1:"工单名称：",
        components2:"请输入内容",
        components3:"对应邀请码：",
        components4:"工单账号数量：",
        components5:"每个账号进粉总目标：",
        components6:"每日进粉数上限：",
        components7:"开启",
        components8:"关闭",
        components9:"每个账号进粉单日进粉目标：",
        components10:"当日重置时间：",
        components11:"开启后，单个账号每日加粉达到设定值后，不再分配该账号且每天准时根据所选时间点，将单日目标完成数置零",
        components12:"备注：",
        components13:"不得超过50字",
        components14:"工单分享密码：",
        components15:"该工单下所有分享链接共用的密码 (4~8位)",
        components16:"活链用户绑定：",
        components17:"开启后，在符合条件的号码中，以顺序循环的方法进行切换。同一个设备每次访问连接同一个号码，防止同一个客户添加多个号码",
        components18:"关闭后，同一个设备每次访问都将重新分配一个新的号码，有可能一个用户添加多个号码",
        components19:"绑定方式：",
        components20:"IP绑定",
        components21:"请输入分享密码",
        components22:"只能包含字母、数字,长度4~8位",
        components23:"请输入工单名称",
        components24:"操作成功"
    },
}