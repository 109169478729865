// 简体中文
import * as menu from './menu'
import * as common from './common'
import * as components from './components'
import * as protocol from './protocol'
import * as Login from './user/login'
import * as register from './user/register'
import * as reset from './user/reset'
import * as workOrderList from './workOrder/list'
import * as workOrderDetail from './workOrder/detail'
import * as workOrderFansDetail from './workOrder/fansDetail'
import * as workOrderAccountLog from './workOrder/accountLog'
import * as workOrderComponents from './workOrder/components'
import * as fansMerchant from './fansMerchant/verify'
import * as fansLinkList from './fansLink/list'
import * as fansLinkComponents from './fansLink/components'
import * as fansLabelList from './fansLabel/list'
import * as comboOrderList from './comboOrder/list';
import * as fansLabelComponents from './fansLabel/components'
import * as codeList from './code/list'
import * as codeDetail from './code/detail'
import * as codeFriendsDetail from './code/friendsDetail'
import * as codeComponents from './code/components'
import * as layoutIndex from './layout/index'
import * as layoutComponents from './layout/components'
import * as customerService from './customerService/index'
import * as firstLogin from './firstLogin'
import * as accountLog from './accountLog'
import * as promoList from './promo/list'
import * as promoDetail from './promo/detail';
import * as promoStatistics from './promo/statistics';
import * as talk from './talk'

export default {
  Menu: menu.default,
  Common: common.default,
  Components: components.default,
  Protocol: protocol.default,
  Login: Login.default,
  Register: register.default,
  Reset: reset.default,
  WorkOrderList: workOrderList.default,
  WorkOrderDetail: workOrderDetail.default,
  WorkOrderFansDetail: workOrderFansDetail.default,
  WorkOrderAccountLog: workOrderAccountLog.default,
  WorkOrderComponents: workOrderComponents.default,
  FansMerchant: fansMerchant.default,
  FansLinkList: fansLinkList.default,
  FansLinkComponents: fansLinkComponents.default,
  FansLabelList: fansLabelList.default,
  ComboOrderList: comboOrderList.default,
  FansLabelComponents: fansLabelComponents.default,
  CodeList: codeList.default,
  CodeDetail: codeDetail.default,
  CodeFriendsDetail: codeFriendsDetail.default,
  CodeComponents: codeComponents.default,
  LayoutIndex: layoutIndex.default,
  LayoutComponents: layoutComponents.default,
  CustomerService: customerService.default,
  FirstLogin: firstLogin.default,
  AccountLog: accountLog.default,
  PromoList: promoList.default,
  PromoDetail: promoDetail.default,
  PromoStatistics: promoStatistics.default,
  Talk: talk.default,
};
